import React, { useContext } from 'react';

import { context as localeContext } from 'context/locale';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { breadcrumbs } from 'constants/translations';
import ActiveOrders from './ActiveOrders';
import TopSales from './TopSales';
import Summary from './Summary';

const Overview: React.FC = () => {
  const { direction } = useContext(localeContext);
  return (
    <Layout top={<Breadcrumbs path={breadcrumbs.OVERVIEW} />} direction={direction}>
      <>
        <Summary />
        <ActiveOrders />
        <TopSales />
      </>
    </Layout>
  );
};

export default Overview;
