import React, { useContext } from 'react';
import cx from 'classnames';

import EmptySales from 'assets/EmptySales.svg';
import { context as localeContext } from 'context/locale';
import { TOP_FIVE_SALES } from 'constants/translations';
import { AnalyticsProduct } from 'service/types/generated/backend_client_index';
import EmptyOverviewState from '../common/EmptyOverviewState';
import ProductCard from './ProductCard';

type TopSalesListProps = {
  bestSellerData: Array<AnalyticsProduct>;
};
const TopSalesList: React.FC<TopSalesListProps> = ({ bestSellerData }) => {
  const { lang } = useContext(localeContext);

  if (!bestSellerData.length) return <EmptyOverviewState title={TOP_FIVE_SALES} Image={EmptySales} />;
  return (
    <>
      {bestSellerData.map((product, index: number) => (
        <div
          key={product.titleEn}
          role="presentation"
          className={cx(
            'w-full bg-gray-100 border items-center cursor-pointer lg:min-w-[440px] ',
            lang === 'en' ? 'mr-3' : 'ml-3',
          )}
        >
          <ProductCard index={index + 1} {...product} />
        </div>
      ))}
    </>
  );
};

export default TopSalesList;
