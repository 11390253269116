import { gql } from '@apollo/client';

export const ORDER = gql`
  query Orders($storeId: String!, $orderId: Int!) {
    order(restaurantId: $storeId, id: $orderId) {
      id
      number
      status
      createdAt
      deliveryStatus
      submittedAt
      updatingStatus {
        orderGettingUpdated
        nextStatus
      }
      deliveryType
      paymentStatus
      cancellationReason
      otherReason
      beachUmbrella {
        number
        specialDirections
      }
      discountedAmount
      paidByWallet
      typeOfRefund
      paidByCreditCard
      refundedAmount
      compensation {
        percentage
        amount
      }
      totalRefund
      branchData {
        titleAr
        titleEn
        lat
        lng
        externalId
        id
      }
      isScheduled
      firingTime
      timeSlot
      expectedAt
      orderItems {
        id
        menuItem {
          id
          titleEn
          titleAr
          photoUrl
          variantPhotoUrl
          variantsTitleAr
          variantsTitleEn
          maxPrepTime
        }
        imageUrl
        notes
        orderId
        properties {
          id
          titleAr
          titleEn
          propertyValues {
            id
            titleAr
            titleEn
            price
            quantity
          }
        }
        quantity
        totalAdditionalCharge
        totalPrice
        unitPrice
        variant {
          titleAr
          titleEn
          prepTime
          price
          discountedPrice
          barCode
          externalId
        }
      }
      deliveryZone {
        zoneName
      }
      userData {
        address {
          area {
            titleAr
            titleEn
            lat
            lng
            cityTitleEn
            cityTitleAr
          }
          building
          street
          floor
          title
          block
          avenue
          unitNo
          unitType
          notes
          lat
          lng
          cityName
          areaName
        }
        car {
          make
          model
          color
          licenseNumber
        }
        phoneNumber
        email
        name
        recipient {
          name
          phoneNumber
          giftNotes
        }
      }
      stateHistories {
        state
        createdAt
        userType
        entityType
        assignee
        assigneeAr
        actionBy
        partnerError
      }
      total
      deliveryFee
      subtotal
      subtotalAfterVoucher
      tax
      taxPercentage
      taxInclusive
      voucherAmount
      voucherCode
      paidThrough
      refundTransactionsHistory {
        refundId
        updatedAt
        status
      }
      deliveryCourierId
      deliveryCourierName
      deliveryCourier {
        id
        driverName
        hasDriverInfo
        driverPhoneNumber
        driverAssigned
        deliveryOrderStatus
        isInternalDelivery
        supportCancellation
        courierId
        courierDetails {
          id
          name
          nameAr
          country
          description
          supportNumber
          businessId
          businessName
          displayNameAr
          displayNameEn
        }
        driverMaxCapacity
        trackingLink
        referenceId
        externalOrderIdentifierLink
        externalOrderIdentifierType
      }
      paymentTransaction {
        id
        status
        chargeId
        paymentDate
      }
      deliveryTime
      gift
      inBetweenTransitions
      partnerErrors
      cashbackAmount
      restaurantRiderBranchOrderData {
        status
        restaurantRider {
          id
          name
          phoneNumber
        }
      }
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation($storeId: String!, $orderId: Int!, $status: OrderStatus!, $deliveryCourierId: Int) {
    updateOrderStatus(restaurantId: $storeId, id: $orderId, status: $status, deliveryCourierId: $deliveryCourierId) {
      id
      status
      paymentStatus
      deliveryCourierId
      inBetweenTransitions
      stateHistories {
        state
        createdAt
        actionBy
      }
    }
  }
`;

export const UPDATE_DELIVERY_COURIER_ID = gql`
  mutation($storeId: String!, $orderId: Int!, $deliveryCourierId: Int!) {
    updateDeliveryCourierId(restaurantId: $storeId, id: $orderId, deliveryCourierId: $deliveryCourierId) {
      id
      deliveryCourierId
    }
  }
`;

export const UPDATE_STATUS_ASYNC = gql`
  mutation($storeId: String!, $orderId: Int!, $status: OrderStatus!, $deliveryCourierId: Int) {
    updateOrderStatusAsync(
      restaurantId: $storeId
      id: $orderId
      status: $status
      deliveryCourierId: $deliveryCourierId
    ) {
      id
      status
      paymentStatus
      deliveryCourierId
      inBetweenTransitions
      stateHistories {
        state
        createdAt
        actionBy
      }
    }
  }
`;

export const UPDATE_BULK_ORDERS_STATUS = gql`
  mutation($storeId: String!, $orders: [BulkChangeOrder], $desiredStatus: String, $operatorId: String) {
    updateBulkOrdersStatus(
      restaurantId: $storeId
      orders: $orders
      desiredStatus: $desiredStatus
      operatorId: $operatorId
    ) {
      id
      number
      status
      createdAt
      submittedAt
      updatingStatus {
        orderGettingUpdated
        nextStatus
      }
      deliveryType
      paymentStatus
      cancellationReason
      otherReason
      discountedAmount
      branchData {
        titleAr
        titleEn
        lat
        lng
        externalId
        id
      }
      isScheduled
      firingTime
      timeSlot
      expectedAt
      orderItems {
        id
        menuItem {
          id
          titleEn
          titleAr
          photoUrl
          variantPhotoUrl
          variantsTitleAr
          variantsTitleEn
          maxPrepTime
        }
        imageUrl
        notes
        orderId
        properties {
          id
          titleAr
          titleEn
          propertyValues {
            id
            titleAr
            titleEn
            price
            quantity
          }
        }
        quantity
        totalAdditionalCharge
        totalPrice
        unitPrice
        variant {
          titleAr
          titleEn
          prepTime
          price
          barCode
          externalId
        }
      }
      userData {
        address {
          area {
            titleAr
            titleEn
            lat
            lng
            cityTitleEn
            cityTitleAr
          }
          building
          street
          floor
          title
          block
          avenue
          unitNo
          unitType
          notes
          lat
          lng
        }
        car {
          model
          color
          licenseNumber
        }
        phoneNumber
        email
        name
        recipient {
          name
          phoneNumber
          giftNotes
        }
      }
      stateHistories {
        state
        createdAt
        actionBy
      }
      total
      deliveryFee
      subtotal
      subtotalAfterVoucher
      tax
      taxPercentage
      taxInclusive
      voucherAmount
      voucherCode
      paidThrough
      refundTransactionsHistory {
        refundId
        updatedAt
        status
      }
      deliveryCourierId
      deliveryCourierName
      deliveryCourier {
        id
        driverName
        hasDriverInfo
        driverPhoneNumber
        driverAssigned
        trackingLink
        referenceId
        externalOrderIdentifierLink
        externalOrderIdentifierType
      }
      paymentTransaction {
        id
        status
        chargeId
        paymentDate
      }
      deliveryTime
      gift
      inBetweenTransitions
      partnerErrors
      cashbackAmount
      restaurantRiderBranchOrderData {
        status
        restaurantRider {
          id
          name
          phoneNumber
        }
      }
    }
  }
`;

export const SETTINGS = gql`
  query($storeId: String!) {
    settings(restaurantId: $storeId) {
      id
      storeTypeId
    }
  }
`;

export const VALIDATE_DELIVERY_COURIER = gql`
  mutation(
    $courierId: Int!
    $branchCoords: Coords!
    $userCoords: Coords!
    $restaurantId: String!
    $restaurantName: String!
    $branchInternalId: String
    $MSApiType: String!
  ) {
    validateDeliveryCourier(
      restaurantId: $restaurantId
      restaurantName: $restaurantName
      courierId: $courierId
      branchCoords: $branchCoords
      userCoords: $userCoords
      branchInternalId: $branchInternalId
      MSApiType: $MSApiType
    ) {
      deliveryFee
      estimatedDeliveryTime
      validationStatus
    }
  }
`;

export const LIST_COURIERS = gql`
  query couriers($businessType: String, $isPublished: Boolean) {
    couriers(businessType: $businessType, isPublished: $isPublished) {
      id
      name
      logoUrl
      integrationData {
        courierBasicInfo {
          title {
            en
            ar
          }
        }
      }
    }
  }
`;
